<template>
  <div class="container" style="padding-top: 10%;">
    systemvariable
  </div>
</template>

<script>
import store from '@/store';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'systemvariable',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {}
};

</script>

